/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState } from 'react';
import styled from 'styled-components';
import { navigateTo } from 'gatsby-link';

import Layout from '../layout/defaultLayout';
import Button from '../components/UI/Button';
import Container from '../components/layout/Container';

function encode(data) {
  return Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
}

function Aansluiten() {
  const [inputs, setInputs] = useState({
    voornaam: {
      label: 'Voornaam speler',
      value: '',
      autoComplete: 'given-name',
      type: 'text',
      required: true,
    },
    achternaam: {
      label: 'Achternaam speler',
      value: '',
      autoComplete: 'family-name',
      type: 'text',
      required: true,
    },
    geboortedatum: {
      label: 'Geboortedatum speler',
      value: '',
      autoComplete: 'bday',
      type: 'date',
      required: true,
    },
    straat: {
      label: 'Straat',
      value: '',
      autoComplete: 'address-line1',
      type: 'text',
      required: true,
    },
    huisnummer: {
      label: 'Huisnummer',
      value: '',
      autoComplete: 'address-line2',
      type: 'text',
      required: true,
    },
    postcode: {
      label: 'Postcode',
      value: '',
      autoComplete: 'postal-code',
      type: 'number',
      required: true,
    },
    gemeente: {
      label: 'Gemeente',
      value: '',
      autoComplete: 'locality',
      required: true,
      type: 'text',
    },
    email: {
      label: 'Email ouder',
      value: '',
      autoComplete: 'email',
      type: 'email',
      required: true,
    },
    telefoon: {
      label: 'Telefoon ouder',
      value: '',
      autoComplete: 'tel',
      type: 'tel',
      required: true,
    },
    mededeling: {
      label: 'Mededeling',
      value: '',
      autoComplete: 'off',
      type: '',
      required: false,
    },
  });

  const handleChange = e => {
    const inputsCp = { ...inputs };
    const updatedInput = { ...inputsCp[e.target.name], value: e.target.value };
    inputsCp[e.target.name] = updatedInput;
    setInputs(inputsCp);
  };

  const handleSubmit = e => {
    e.preventDefault();
    const netlifyBody = {};
    Object.keys(inputs).forEach(inputKey => {
      const { value } = inputs[inputKey];
      netlifyBody[inputKey] = value;
    });
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...netlifyBody,
      }),
    })
      .then(() => navigateTo(form.getAttribute('action')))
      .catch(error => alert(error));
  };

  return (
    <Layout>
      <Container>
        <h1>Hoe aansluiten?</h1>
        <p>
          Wil je zelf graag basketbal te spelen? Zoek je voor uw zoon of dochtertje de ideale club?
        </p>
        <p>
          Oxaco BBC is een <strong>warme familieclub</strong> waar iedereen welkom is. Onze club
          biedt al 60 jaar basket plezier en staat gekend voor een{' '}
          <strong>zeer degelijke opleiding</strong> voor enthousiaste jeugdspelers van 4 tot 21
          jaar.
        </p>
        <p>
          Jeugdwerking is de rode draad door de geschiedenis van de club. <br />
          <strong>Jeugdwerking is de ruggengraat van Oxaco BBC.</strong>
        </p>
        <h2>Wij bieden volgende mogelijkheden aan:</h2>
        <ul style={{ listStyleType: 'none' }}>
          {/* <li>
          <h3>Kleuters en kinderen van 3 – 7 jaar</h3>
          <p>
            Kinderen die nog niet oud genoeg zijn om aan de basisopleiding te beginnen kunnen reeds
            starten met <strong>de Bewegingsschool</strong>.
          </p>
          <p>
            De Oxaco bewegingsschool is een initiatief om kinderen{' '}
            <strong>op een speelse manier te leren "bewegen"</strong>, zonder te focussen op één
            sport en dit onder deskundige begeleiding. Er wordt aandacht geschonken aan: fysieke
            fitheid, basismotoriek, uithouding, lenigheid, balgevoel, ter bevordering van hun{' '}
            <strong>lichamelijke en geestelijke ontplooiing</strong>. Oxaco Bewegingsschool is{' '}
            <strong>door de Vlaamse overheid geselecteerd</strong> voor het Multimoveproject en
            verkrijgt hierdoor extra ondersteuning en begeleiding.
          </p>
          <p>
            Zondagmorgen van 09.30 uur tot 10.30 uur, op het domein Capenberg – Borsbeeksesteenweg
            45 te 2530 Boechout. <br />
            Voor meer info over de Bewegingsschool kan u terecht op de website{' '}
            <strong>
              <a
                href="https://www.oxaco-bewegingsschool.be"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.oxaco-bewegingsschool.be
              </a>
            </strong>
          </p>
        </li> */}
          <li>
            <h3>De basisopleiding</h3>
            <p>Kinderen van volgende leeftijden kunnen deelnemen aan de basisopleiding:</p>
            <ul>
              <li>U8 (5 – 6 – 7 jaar)</li>
              <li>U10 (8 – 9 jaar)</li>
              <li>U12 (10 - 11 jaar)</li>
              <li>U14 (12 -13 jaar)</li>
            </ul>
          </li>
          <li>
            <h3>De leeftijden voor gevorderde spelers:</h3>
            <p>Voor de gevorderde spelers hebben we volgende ploegen:</p>
            <ul>
              <li>U16 (14 – 15 jaar)</li>
              <li>U18 (16 – 17 jaar)</li>
              <li>U21 (18 - 19 -20 jaar)</li>
            </ul>
          </li>
        </ul>
        <p>
          Elke spelers / speelster heeft recht op 4 gratis proeftrainingen met nadien vrijblijvend
          de optie om bij de club aan te sluiten. Indien u een proeftraining wenst aan te vragen,
          kan u onderstaand contactformulier invullen of contact opnemen met onze
          rekruteringsverantwoordelijke Paul Hendrix{' '}
          <a href="mailto:oxacobbc.aansluiten@gmail.com">oxacobbc.aansluiten@gmail.com</a>
        </p>
        <StyledForm>
          <form
            name="aansluitingsaanvraag"
            className="contactForm"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            action="/bedankt"
            onSubmit={handleSubmit}
          >
            <input type="hidden" name="form-name" value="aansluitingsaanvraag" />
            <p hidden>
              <label>
                Don’t fill this out: <input name="bot-field" onChange={handleChange} />
              </label>
            </p>
            <h2>Contacteer ons</h2>
            {Object.keys(inputs).map(inputKey => {
              const { label, value, autoComplete, type, required } = inputs[inputKey];
              return (
                <label htmlFor={inputKey} key={`input-${inputKey}`}>
                  {label}
                  <br />
                  {inputKey === 'textarea' ? (
                    <textarea
                      id={inputKey}
                      type={type}
                      autoComplete={autoComplete}
                      name={inputKey}
                      value={value}
                      required={required}
                      onChange={handleChange}
                    />
                  ) : (
                    <input
                      // placeholder="Voornaam speler"
                      id={inputKey}
                      type={type}
                      autoComplete={autoComplete}
                      name={inputKey}
                      value={value}
                      required={required}
                      onChange={handleChange}
                    />
                  )}
                </label>
              );
            })}
            {/* <div data-netlify-recaptcha></div> */}
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button type="submit">Verzend</Button>
            </div>
          </form>
        </StyledForm>
      </Container>
    </Layout>
  );
}

export default Aansluiten;

const StyledForm = styled.div`
  .contactForm {
    padding: 40px;
    background-color: #fff;
    max-width: 800px;
    margin: 40px auto;
    box-shadow: rgba(0, 0, 0, 0.11) 0px 8px 24px 0px;
    box-sizing: border-box;
    border-radius: 10px;
    @media (max-width: 768px) {
      width: 100%;
      padding: 20px;
    }
    h2 {
      color: #01600f;
      font-size: 28px;
      font-weight: 500;
      margin-top: 0;
      margin-bottom: 30px;
    }
    input,
    textarea {
      margin: 10px 0;
      border: 1px solid #ccc;
      border-radius: 10px;
      background: #fff;
      width: 100%;
      padding: 15px;
      box-sizing: border-box;
      font-size: 16px;
      &:hover {
        transition: border-color 0.3s ease-in-out;
        border: 1px solid #aaa;
      }
    }
    label {
      margin-top: 10px;
      display: block;
      font-weight: bold;
    }
    #adres p {
      margin-bottom: 0px;
    }
    textarea {
      height: 200px;
    }
    button[type='submit'] {
      margin-top: 20px;
    }
    #postalCodeCityContainer,
    #streetNumberContainer {
      display: flex;
      @media (max-width: 576px) {
        flex-direction: column;
      }
    }

    #postalCodeCityContainer {
      label[for='postalCode'] {
        flex-grow: 1;
        @media (min-width: 577px) {
          margin-right: 20px;
          max-width: 200px;
        }
      }
      label[for='city'] {
        flex-grow: 3;
      }
    }
    #streetNumberContainer {
      label[for='street'] {
        flex-grow: 3;
        @media (min-width: 577px) {
          margin-right: 20px;
        }
      }
      label[for='houseNumber'] {
        @media (min-width: 577px) {
          max-width: 160px;
        }
      }
    }
  }
`;
